// Dark ————————————————————————————————————————————————————————————————

@media (prefers-color-scheme: dark) {
  $color-black: #000;
  $color-black-mix: hsl(220, 40%, 3%);

  $color-text-key: mix($color-white, $color-key, 10);

  $shade-darkest: fade-out($color-black, 0.2);
  $shade-darker: fade-out($color-black, 0.4);
  $shade-dark: fade-out($color-black, 0.5);
  $shade-mid: fade-out($color-black, 0.7);
  $shade-light: fade-out($color-black, 0.85);
  $shade-lighter: fade-out($color-black, 0.9);
  $shade-lightest: fade-out($color-black, 0.94);

  $grey-darkest: mix($color-black-mix, $color-white, 20);
  $grey-darker: mix($color-black-mix, $color-white, 40);
  $grey-dark: mix($color-black-mix, $color-white, 66);
  $grey-mid: mix($color-black-mix, $color-white, 76);
  $grey-light: mix($color-black-mix, $color-white, 84);
  $grey-lighter: mix($color-black-mix, $color-white, 90);
  $grey-lightest: mix($color-black-mix, $color-white, 94);

  :root {
    --color-black: #{$color-black};

    --color-text-key: #{$color-text-key};

    --color-root: var(--color-black);
    --color-root-backdrop: #{$shade-darker};

    --color-contrast: var(--color-white);

    --contrast-darkest: #{$tint-darkest};
    --contrast-darker: #{$tint-darker};
    --contrast-dark: #{$tint-dark};
    --contrast-mid: #{$tint-mid};
    --contrast-light: #{$tint-light};
    --contrast-lighter: #{$tint-lighter};
    --contrast-lightest: #{$tint-lightest};

    --shade-darkest: #{$shade-darkest};
    --shade-darker: #{$shade-darker};
    --shade-dark: #{$shade-dark};
    --shade-mid: #{$shade-mid};
    --shade-light: #{$shade-light};
    --shade-lighter: #{$shade-lighter};
    --shade-lightest: #{$shade-lightest};

    --grey-darkest: #{$grey-darkest};
    --grey-darker: #{$grey-darker};
    --grey-dark: #{$grey-dark};
    --grey-mid: #{$grey-mid};
    --grey-light: #{$grey-light};
    --grey-lighter: #{$grey-lighter};
    --grey-lightest: #{$grey-lightest};

    --color-text: var(--color-contrast);
    --color-focus: var(--color-key-50);
    --color-text-bad: #{lighten($color-bad, 10)};

    --button-bad-focus-color: var(--color-bad-50);

    --color-button-a: hsl(220, 60%, 16%);
    --color-button-c: hsl(42, 60%, 16%);
  }

  ::selection {
    background-color: mix($color-black, $color-key, 30);
  }

  a {
    &:focus,
    &:hover {
      color: var(--color-key-lighter);
    }
  }

  .synth-keys._gamepad {
    .synth-key-inner:before {
      background-color: var(--contrast-lighter);
    }

    .synth-key-inner:after {
      filter: invert(1);
    }

    // A Button

    [data-note='D5'] {
      .synth-key-inner:before {
        background-color: fade-out(#ddf, 0.88);
      }
    }

    // C Buttons

    [data-note='F5'],
    [data-note='A5'],
    [data-note='B5'],
    [data-note='D6'] {
      .synth-key-inner:before {
        background-color: fade-out(#fdc, 0.82);
      }
    }
  }

  .drawer-card {
    --color-root: var(--grey-lighter);
  }
  .screen-modal .screen {
    --color-root: var(--grey-mid);
  }
}
