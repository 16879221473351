// Root ————————————————————————————————————————————————————————————————

:root {
  --font-base: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-body: var(--font-base);

  font-family: var(--font-body);
  font-weight: 400;
  line-height: (20/16);

  overflow: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: var(--color-key-20);

  background-color: var(--color-root);
  color: var(--color-text);
}

* {
  -webkit-touch-callout: none;
  user-select: none;
}

*,
:before,
:after {
  box-sizing: inherit;
  font-weight: inherit;
}

::selection {
  background-color: mix($color-white, $color-key, 80);
}

:focus {
  outline: 3px solid var(--color-focus);
}

:root,
body,
#app,
.screen {
  height: 100%;
}

a {
  color: var(--color-key);
  text-decoration: none;
  transition: color 0.1s, background-color 0.1s, outline-color 0.1s;

  &:focus,
  &:hover {
    color: var(--color-key-darker);
  }

  @include focus-outline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
