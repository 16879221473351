// Start ———————————————————————————————————————————————————————————————

.screen {
  overflow: hidden;
  background-color: var(--color-root);
  transform: scale3d(1, 1, 1);
  // transform-origin: 50% 50%;
  transition: background-color, border-radius, transform;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);

  .is-app & {
    transform-origin: 50% 0%;
  }
}

.screen-modal {
  .screen {
    --color-root: var(--grey-lightest);
    $scale: (345/375);
    transform: scale3d($scale, $scale, 1);
    border-radius: rem(9);

    @media (min-width: 40em) {
      $scale: (620/640);
      transform: scale3d($scale, $scale, 1);
    }

    @media (orientation: landscape) {
      transform: none;
      border-radius: 0;
    }
  }
}

.screen,
main {
  flex: auto;
  display: flex;
  flex-direction: column;
}

header {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  touch-action: none;
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right);

  button._inner {
    flex: auto;
    padding: var(--space-rem-mid);

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }

  .status {
    position: absolute;
    z-index: 1;
    bottom: rem(13);
    right: rem(14);
  }
}

header button[aria-label^='Layout:'] .icon-layout {
  --element-color: var(--contrast-light);
  --hover-element-color: var(--contrast-darker);
}

header button[aria-controls='drawer'] {
  &,
  &:focus,
  &:hover {
    --fill: var(--color-contrast);
  }

  .icon-ocarina {
    --potato-color: var(--fill);
    --ring-color: var(--fill);

    margin-right: em(-3);
    opacity: 0.15;
    transition: opacity 0.1s;

    .holes,
    .symbol {
      display: none;
    }
  }

  &:focus,
  &:hover {
    .icon-ocarina {
      opacity: 0.6;
    }
  }
}

main {
  padding-bottom: env(safe-area-inset-bottom);
}

.status {
  --size: #{em(10)};
  --color: var(--grey-light);

  pointer-events: none;

  &:after {
    content: '';
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    background-color: var(--color);
    border: 1px solid var(--color-root);
  }

  &[data-state='good'] {
    --color: var(--color-good);
  }

  &[data-state='indeterminate'] {
    --color: var(--color-caution);
  }

  &[data-state='bad'] {
    --color: var(--color-bad);
  }
}

.error {
  padding: var(--space-rem-mid) var(--space-rem-mid);
  color: var(--color-text-bad);
  background-color: var(--color-bad-10);
  overflow: auto;

  pre {
    margin: 0 0 var(--space-rem-mid);
    white-space: pre-wrap;
  }

  button {
    @extend ._bad;
  }
}

svg.symbol {
  font-size: em(24);
  height: em(24, 24);
  width: auto;
  margin: em(-8, 24) 0 em(-4, 24);
  fill: currentColor;
  vertical-align: middle;
}

.message {
  display: flex;
  align-items: center;
  padding: var(--space-rem-mid) var(--space-rem-mid);

  pre {
    margin: 0 0 var(--space-rem-mid);
    white-space: pre-wrap;
  }

  p {
    line-height: (24/16);
  }

  button {
    @extend ._key;
  }

  &._key {
    color: var(--color-text-key);
    background-color: var(--color-key-10);
  }

  &._error {
    color: var(--color-text-bad);
    background-color: var(--color-bad-10);

    button {
      @extend ._bad;
    }
  }
}

.message-content {
  flex: auto;

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: var(--space-rem-mid);
  }
}

.message-thumbnail {
  flex: none;

  &:first-child {
    margin-right: var(--space-rem-mid);
  }

  &:last-child {
    margin-left: var(--space-rem-mid);
  }
}

.drawer .message._is-ios .message-thumbnail {
  border-radius: 20%;
  max-width: rem(60);
  box-shadow: 0 0 0 1px var(--shade-lightest);
}
