// Transitions —————————————————————————————————————————————————————————
$slide-duration: 0.5s;

.drawer-slide-enter-active,
.drawer-slide-leave-active {
  transition: visibility $slide-duration;

  .drawer-overlay {
    transition: opacity $slide-duration;
  }
}

.drawer-slide-enter-active .drawer-card,
.drawer-slide-leave-active .drawer-card {
  transition: transform $slide-duration cubic-bezier((1/4), (10/12), (1/10), 1);
}

.drawer-slide-enter,
.drawer-slide-leave-to {
  .drawer-overlay {
    opacity: 0;
  }
  .drawer-card {
    transform: translateY(100%);
  }
}
