// Drawer ——————————————————————————————————————————————————————————————

.drawer {
  --color-root: var(--color-white);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}

.drawer .icon-ocarina {
  --potato-color: var(--color-key);
  --ring-color: var(--color-key-lighter);
  --symbol-color: var(--color-key);
}

.drawer-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.drawer-card-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  padding-top: percentage(35/375);

  .is-app & {
    padding-top: rem(10);
  }

  @media (orientation: landscape) {
    padding-top: rem(10);
  }
}

.drawer-card {
  max-width: 26em;
  margin: auto;
  pointer-events: auto;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: var(--color-root);
  border-radius: rem(10) rem(10) 0 0;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20vh;
  box-shadow: 0 0 24px var(--shade-mid);

  .is-app & {
    box-shadow: 0 4px 12px var(--shade-light), 0 0 0 0.5px var(--shade-lighter);
  }
}

.drawer-header {
  background-color: var(--color-root);
  box-shadow: 0 1px 0 var(--shade-lighter);
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;

  button {
    display: block;
    width: 100%;
    line-height: 1;
    text-align: right;
    font-size: em(28);
    padding-right: var(--space-rem-big);

    &:focus .icon-close {
      box-shadow: 0 0 0 3px var(--contrast-light);
    }
  }
}

.drawer-header h1 {
  display: flex;
  margin: var(--space-rem-mid) var(--space-rem-mid) var(--space-rem-small);
  padding-top: rem(12);

  .icon-ocarina {
    top: em(-5, 32);
    margin-right: em(-1, 32);
  }
}

.drawer .form-blocks {
  padding-left: var(--space-rem-mid);
  padding-right: var(--space-rem-mid);
  margin: 0;
  box-shadow: 0 -1px 0 var(--shade-lightest);
}

.drawer footer {
  @include font-size(13, 16);
  box-shadow: 0 -1px 0 var(--shade-lightest);
  color: var(--color-text-light);
  padding: var(--space-rem-small) var(--space-rem-mid);

  p {
    margin: var(--space-rem-small) 0;
  }
}
