// Synth ———————————————————————————————————————————————————————————————

.synth-keys,
.synth-keys-inner {
  flex: auto;
  display: flex;
}

.synth-keys-inner {
  flex-wrap: wrap-reverse;
}

@keyframes pulse {
  from {
    opacity: var(--pulse-from);
  }
  to {
    opacity: var(--pulse-to);
  }
}

.synth-key {
  --color: var(--color-key);
  --background-color: var(--color-key-20);
  --hover-background-color: var(--color-key-30);

  --pulse-from: 0.5;
  --pulse-to: 0.4;

  position: relative;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  width: (100%/3);
  border: 1px solid var(--color-root);
  border-top: none;
  border-left: none;
  border-radius: 0;
  padding: 0;
  text-align: center;

  .synth-key-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    pointer-events: none;
  }

  .synth-key-inner:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: var(--hover-background-color);
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &[aria-pressed='true'],
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    background-color: var(--background-color);
    box-shadow: none;
  }

  &:hover {
    .synth-key-inner:before {
      opacity: 0.12;
    }
  }

  &[aria-pressed='true'] {
    .synth-key-inner:before {
      $vibrato-rate: 1s / 6;
      animation: pulse ($vibrato-rate * 1) ease-in-out infinite alternate;
    }
  }

  @media (min-width: 36em) {
    width: (100%/5);
  }

  @media (min-width: 96em) {
    width: (100%/15);
  }
}

.synth-keys._rotated {
  font-size: min(8em, (100vw/3.5));
  display: flex;
  align-items: center;
  justify-content: center;

  .synth-keys-inner {
    width: 3em;
    height: auto;
    margin: 0;
    margin-bottom: 1em;
    flex: none;
    transform: rotateZ(-45deg) translateX(50% * -(9/8));
  }

  .synth-key {
    position: relative;
    display: flex;
    justify-content: center;
    flex: none;
    font-size: 1em;
    width: (3em/8);
    height: (9em/8);
    padding: 0;
  }

  .synth-key:nth-child(5n + 1),
  .synth-key:nth-child(5n + 3),
  .synth-key:nth-child(5n + 5) {
    width: (6em/8);
  }

  .synth-key:nth-child(n + 6) {
    left: (9em/8);
  }

  .synth-key:nth-child(n + 11) {
    left: (18em/8);
  }

  .synth-key:nth-child(n + 16) {
    left: (27em/8);
  }

  .synth-key-label {
    font-size: 1rem;
    transform: rotateZ(45deg);
  }
}

.synth-keys._gamepad {
  .synth-key {
    --color: var(--contrast-darker);
    --background-color: var(--contrast-lighter);
    --hover-background-color: var(--background-color);

    --pulse-from: 1;
    --pulse-to: 0.8;

    background-color: transparent;

    &:hover {
      .synth-key-inner:before {
        opacity: 0.3;
      }
    }
  }

  .synth-key-inner {
    position: relative;
    color: var(--color);
    background-color: var(--background-color);
    width: em(88, 32);
    height: em(88, 32);
    border-radius: 4em;
  }

  // Pressed style

  .synth-key-inner:before {
    background: var(--contrast-light);
  }

  // A Button

  [data-note='D5'] {
    --background-color: var(--color-button-a);

    .synth-key-inner:after {
      content: '';
      background-image: url('/img/buttons/button-a.svg');
    }

    .synth-key-inner:before {
      background-color: fade-out(#005, 0.8);
    }
  }

  // C Buttons

  [data-note='F5'],
  [data-note='A5'],
  [data-note='B5'],
  [data-note='D6'] {
    --background-color: var(--color-button-c);

    .synth-key-inner:after {
      content: '';
      background-image: url('/img/buttons/button-c.svg');
    }

    .synth-key-inner:before {
      background-color: fade-out(#a00, 0.86);
    }
  }

  [data-note='F5'] .synth-key-inner:after {
    transform: rotateZ(180deg);
  }
  [data-note='A5'] .synth-key-inner:after {
    transform: rotateZ(90deg);
  }
  [data-note='B5'] .synth-key-inner:after {
    transform: rotateZ(270deg);
  }
}

// Rotated Gamepad

.synth-keys._rotated._gamepad {
  .synth-keys-inner {
    margin-bottom: em(16, 128);
  }

  .synth-key {
    background-color: transparent;
    border-width: 0;
    transition: none;
  }

  .synth-key-inner {
    position: relative;
    padding: 0;
    width: em(106, 128);
    height: em(106, 128);
    margin: em(-8, 128);
    border-radius: 4em;
  }

  .synth-key:nth-child(5n + 2),
  .synth-key:nth-child(5n + 4) {
    .synth-key-inner {
      width: em(20, 128);
      height: em(20, 128);
      z-index: -1;
    }
  }

  .synth-key-label {
    visibility: hidden;
    font-size: rem(21);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotateZ(45deg);
  }

  // In-game buttons

  .synth-key-inner:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotateZ(45deg);
    opacity: (1/4);
  }

  [data-note='F5'] .synth-key-inner:after {
    transform: rotateZ(45 + 180deg);
  }
  [data-note='A5'] .synth-key-inner:after {
    transform: rotateZ(45 + 90deg);
  }
  [data-note='B5'] .synth-key-inner:after {
    transform: rotateZ(45 + 270deg);
  }

  // Hidden

  [data-note='F4'],
  [data-note='G4'],
  [data-note='C7'],
  [data-note='D7'] {
    visibility: hidden;
  }
}

.synth-keys:not(._rotated) {
  [data-note='F4'],
  [data-note='G4'],
  [data-note='B6'],
  [data-note='C7'],
  [data-note='D7'] {
    display: none;
  }
}
