




















.icon-ocarina {
  --potato-color: #{mix(#fff, #000, 60)};
  --holes-color: #{mix(#fff, #000, 0)};
  --ring-color: #{mix(#fff, #000, 40)};
  --symbol-color: #{mix(#fff, #000, 50)};

  --potato-stop-light: hsl(200, 100%, 70%);
  --potato-stop-dark: hsl(232, 100%, 64%);
  --ring-stop-light: hsl(216, 100%, 96%);
  --ring-stop-dark: hsl(216, 100%, 80%);

  display: inline-block;
  position: relative;
  vertical-align: bottom;

  svg {
    display: block;
    position: relative;
    width: 2em;
    margin: (-8em/16) (-3em/16);
  }

  button:hover & {
    --potato-color: #{mix(#fff, #000, 48)};
    --ring-color: #{mix(#fff, #000, 30)};
    --symbol-color: #{mix(#fff, #000, 44)};
  }

  path {
    transition: fill 0.1s;
  }

  path.potato {
    fill: var(--potato-color);
  }
  path.holes {
    fill: var(--holes-color);
  }
  path.ring {
    fill: var(--ring-color);
  }
  path.symbol {
    fill: var(--symbol-color);
  }

  &._gradient {
    path.potato {
      fill: url(#potato-gradient);
    }
    path.ring {
      fill: url(#ring-gradient);
    }
  }
}
